#home-about {
  #image-home-left {
    height: 245px;
    width: 245px;
  }

  #logo-div-img {
    margin-top: 44px;
  }

  #home-image-left {
    margin-top: 21px;
  }

  #text-container {
    text-align: center;
  }

  #images-text-container-home {
    margin: 120px 0 120px 0;
  }

  .col-images {
    text-align: center;
    img {
      height: 200px;
      width: 200px;
      max-width: none;
    }
  }

  hr {
    width: 60%;
    border: 3px solid red;
    margin-left: 20%;
    margin-bottom: 30px;
  }

  h6.feature-title {
    color: black;
    padding-top: 10px;
  }

  .about-feature-list {
    .our-feature {
      margin-left: 20%;
    }
  }
}

#audio-title {
  margin-left: 10px;
}
